import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createScore, getScore } from "../../redux/actions/scoreAction";

import {
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel,
  Radio, Button, TextField, Grid, withStyles, CircularProgress
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import Filter9PlusIcon from '@material-ui/icons/StarHalf';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Alert, AlertTitle } from "@material-ui/lab";
import React from 'react';
import { connect } from "react-redux";
import { fetchInventoryHistory } from "../../redux/actions/inventoryHistoryActions";
import { fetchEvaluations } from "../../redux/actions/evaluationsActions";
import { fetchScore } from "../../redux/actions/scoreActions";
import "../../assets/css/online-inventory.css";
import { getCertificate } from "../../redux/actions/assignedAssetActions";
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, getIdOptionSUSIList, getNameOptionSUSIList } from "../../redux/actions/SUSIListsActions";
import { DocumentService } from "../../services/generateDocumentService";
import { pdf } from "@react-pdf/renderer";
import { fetchAssetStages } from "../../redux/actions/assetStagesActions";
import { fetchLocations } from "../../redux/actions/locationsActions";
import { fetchSectionalsUser } from "../../redux/actions/sectionalsActions";
import { fetchAllNotifications } from "../../redux/actions/notificationsActions";
import { Label } from "@material-ui/icons";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Slider from '@material-ui/core/Slider';
import {DOCUMENTS_FOLDER} from "../../config";


const CustomSlider = withStyles({
  mark: {
    backgroundColor: 'transparent',
    height: 8,
    width: 8,
    border: '2px solid #3f51b5',
    borderRadius: '50%',
    marginTop: -3,
  },
  markActive: {
    backgroundColor: '#3f51b5',
  },
})(Slider);

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5',
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class InventoryLeftOver extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textSearch: "",
      openDialogSort: false,
      openDialogFilter: false,
      valueSort: 'ASC',
      valueFilter: '',
      optionSelected: null,
      filterApplied: false,
      orderBy: 'articleTitle',
      order: 'asc',
      filterText: '',
      filteredData: [],
      openView: false,
      openScore: false,
      newScore : [],
      paramsFetchSectionals: {
        user_susi_id: props.user.user_susi_id,
      },
      clean: false,
      pdfBlobUrl: ''
    };
  }
 

  componentDidMount() {
    console.log("TLA en toreport (inventoryhistory 56");
    this.getInformation();
    //this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
    // this.props.dispatch(fetchAssetsStatus());
    // this.props.dispatch(fetchChangesTypes());
    // this.props.dispatch(fetchAssetStages());
    // this.props.dispatch(fetchMissingReasons());
    // this.props.dispatch(fetchLocations());
    // this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
    this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
  }

  getInformation = () => {
    const params = {
      idUser: this.props.user.numero_documento,
      order: this.state.valueSort,
      vigencia: this.state.valueFilter
    };
    //this.props.dispatch(fetchEvaluations(params));
    this.props.dispatch(fetchScore(params));
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.state.clean) {
      this.setState({ clean: false });
      this.appliedFilter()
    }

  }


  handleSortRequest = (property) => {
    const orderBy = property;
    let order = 'desc';
  
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
  
    this.setState({ order, orderBy });
  };
  
  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };


  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };
  
  handleFilterChange = (event) => {
    const filterText = event.target.value.toLowerCase(); // Convertir a minúsculas
  
    const { evaluations } = this.props.evaluationsList;
  
    if (!Array.isArray(evaluations)) {
      return;
    }
  
    this.setState({
      filterText: filterText
    }, () => {
      const filteredData = evaluations.filter((item) => {
        return (
          item.articleTitle.toLowerCase().includes(this.state.filterText) ||
          item.inscriptionDate.toLowerCase().includes(this.state.filterText) ||
          item.competitionId.toString().includes(this.state.filterText) // Convertir a cadena de texto
        );
      });
  
      this.setState({
        filteredData
      });
    });
  };

  downloadFile = async (inscriptionId) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const pdfPath = process.env.PUBLIC_URL + DOCUMENTS_FOLDER + currentYear + '/SummaryPdf_' + inscriptionId + '.pdf';
    console.log(pdfPath);
    console.log(process.env.PUBLIC_URL);
    // Descargar el archivo PDF como un Blob
    const response = await fetch(pdfPath);
    const blob = await response.blob();

    // Crear una URL para el Blob
    const blobUrl = URL.createObjectURL(blob);

    // Actualizar el estado con la URL del Blob
    this.setState({ pdfBlobUrl: blobUrl });
  }


  handleOpenView = (inscriptionId) => {
    this.downloadFile(inscriptionId);
    this.setState({
      openView: true
    });
  };

  handleCloseView = () => {
    this.setState({
      openView: false
    });
  };

  // handleOpenScore = (inscriptionId) => {
  //   console.log("inscriptionId pasado");
  //   console.log(inscriptionId);
  //   const registrarScore = this.props.evaluationsList.evaluations.find(score => score.inscriptionId === inscriptionId);
  //   console.log(registrarScore);

  //   this.setState({
  //     openScore: true
  //   });
  // };

  // handleCloseScore = () => {
  //   this.setState({
  //     openScore: false
  //   });
  // };

  handleOpenScore = (inscriptionId) => {
    this.setState({ openScore: true, selectedInscriptionId: inscriptionId });
    const newScore = this.props.scoreList.score.filter((score) => score.inscriptionId == inscriptionId);
    this.setState({ newScore: newScore });
    console.log("ESTE ES EL SCORE");
    console.log(inscriptionId);
    console.log(newScore);
    console.log(this.state.newScore);
  };

// handleSliderChange = (groupId, itemIndex, newValue) => {
//     // Clonar el estado actual de newScore
//     console.log("llega");
//     console.log(groupId);
//     console.log(itemIndex);
//     console.log(newValue);
//     console.log(this.state.newScore);
//     const updatedScore = [...this.state.newScore];
//     // // Encontrar el índice del grupo dentro de newScore
//     // const groupIndex = updatedScore.findIndex((item) => item.formOrderGroup === groupId);
//     // if (groupIndex !== -1) {
//     //   // Encontrar el índice del elemento dentro del grupo correspondiente
//       const itemInGroupIndex = updatedScore.findIndex((item) => item.groupId == groupId & item.formOrderItem == itemIndex);
//       console.log(itemInGroupIndex);
//       if (itemInGroupIndex !== -1) {
//         // Actualizar el valor del slider correspondiente utilizando el índice dentro del grupo
//         updatedScore[itemInGroupIndex].sliderValue = newValue;
//         console.log(updatedScore);
//         // Actualizar el estado con el nuevo valor del slider
//         this.setState({ newScore: updatedScore });
//         console.log(this.state.newScore);
//       }
//   };

handleSliderChange = (groupId, itemIndex, newValue) => {
  // Clonar el estado actual de newScore
  const updatedScore = [...this.state.newScore];

  // Encontrar el índice del elemento dentro del grupo correspondiente
  const itemInGroupIndex = updatedScore.findIndex((item) => item.groupId === groupId && item.formOrderItem === itemIndex);
  
  if (itemInGroupIndex !== -1) {
    // Actualizar el valor del slider correspondiente utilizando el índice dentro del grupo
    updatedScore[itemInGroupIndex].sliderValue = newValue;

    // Actualizar el estado con el nuevo valor del slider
    this.setState({ newScore: updatedScore });
  }
};


handleInputChange = (groupId, itemIndex, event) => {
    // Clonar el estado actual de newScore
    const updatedScore = [...this.state.newScore];
    //   // Encontrar el índice del elemento dentro del grupo correspondiente
    const itemInGroupIndex = updatedScore.findIndex((item) => item.groupId == groupId & item.formOrderItem == itemIndex);
    console.log(itemInGroupIndex);
    if (itemInGroupIndex !== -1) {
      // Actualizar el valor del slider correspondiente utilizando el índice dentro del grupo
      updatedScore[itemInGroupIndex].inputValue = event.target.value;
      console.log(updatedScore);
      // Actualizar el estado con el nuevo valor del slider
      this.setState({ newScore: updatedScore });
      console.log(this.state.newScore);
    }



    // Encontrar el índice del grupo dentro de newScore
    // const groupIndex = updatedScore.findIndex((item) => item.groupId === groupId);
    // if (groupIndex !== -1) {
    //   // Encontrar el índice del elemento dentro del grupo correspondiente
    //   const itemInGroupIndex = updatedScore[groupIndex].items.findIndex((item) => item.formId === itemIndex);
    //   if (itemInGroupIndex !== -1) {
    //     // Actualizar el valor del textarea correspondiente utilizando el índice dentro del grupo
    //     updatedScore[groupIndex].items[itemInGroupIndex].inputValue = event.target.value;
    //     // Actualizar el estado con el nuevo valor del textarea
    //     this.setState({ newScore: updatedScore });
    //   }
    // }
  };


  handleCloseScore = () => {
    this.setState({ openScore: false, selectedInscriptionId: null });   
  };

  addScore = async () => {
  const allSlidersHaveScore = this.state.newScore.every(score => {
      if (score.dataType == 1) {
          return score.sliderValue != null && score.sliderValue != 0;
      }
      return true;
  });

  if (!allSlidersHaveScore) {
      alert('Por favor, asegúrate de que todos los sliders con dataType 1 tengan un puntaje.');
      return;
  }
    console.log("puntajes");
    console.log(this.state.newScore);
    const formData = new FormData();
      // Agrega archivos al FormData
    //formData.append('articleImage1', selectedFile1 ? selectedFile1 : null);
    formData.append('scores', JSON.stringify(this.state.newScore));
  
    let errors = [];
  
    // if (!newRegister.memberIdcard) {
    //   errors.push('Sin identificación del miembro ACHO .');
    // }

  
    if (errors.length == 0 ) {  
        console.log("INSCRIPCION");
  
  //Graba el registro      TLA nuevo graba
        let dataInscription= await createScore(formData) 
        if(dataInscription) {
              console.log("INSCRIPCION");
              console.log(dataInscription);
        //ENVIAR CORREO
              let email = window.sessionStorage.getItem('_displayMail');
              let params = {
              subject: 'Inscripción concurso ACHO'.toString('utf8'),
              emailEnd: email,
              contenido: 'Se ha inscrito satisfactoriamente al concurso',
              // inscriptionId: dataInscription['inscriptionId'];
              }
             // let result = await sendMail(params)
             let result = [];
              if (result) {
                // setEmailAlert(result)
                alert('Ha sido enviado a su correo registrado el soporte de inscripción. Favor verifica')
              } else {
                // setEmail{Alert('')
                alert('Ha sido enviado a su correo registrado el soporte de inscripción. Favor verifica')
              }
  
              // document.getElementById('companyName').value = '';
              // document.getElementById('companyDepartament').value = '';
              // Reiniciar el estado de validación
              //setIsRegisterValid(true);
            
  
            //TLA nuevo grabar
            //setTemporalFiliations([]);
            
            }
            
    } else {
      //state.inscriptionState=0;
      //formData.append('inscriptionState', state.inscriptionState);
      await createScore(formData)
      // Concatenar todos los errores en una sola cadena de texto
      const errorMessage = errors.join('\n');
      
      // Mostrar mensaje de alerta con todos los errores
      alert(errorMessage);

    }
    this.handleCloseView();
  };


  render() {
    console.log("estados");
    console.log(this.props);

    const { evaluationsList } = this.props;
    const { order, orderBy, filterText, filteredData, openScore, selectedInscriptionId } = this.state; 
    const dataToRender = filterText ? filteredData : evaluationsList.evaluations;



   // Agrupar los ítems por groupId
   const groupedItems = this.state.newScore.reduce((groups, item) => {
    const groupIndex = groups.findIndex(group => group.groupId === item.groupId);
    
    if (groupIndex === -1) {
      groups.push({
        groupId: item.groupId,
        groupDescription: item.groupDescription,
        items: [item]
      });
    } else {
      groups[groupIndex].items.push(item);
    }

    return groups;
  }, []);

  // Ordena los grupos por su orden
  groupedItems.sort((a, b) => a.groupOrder - b.groupOrder);

  // Calcular el total del puntaje por grupo
  const totalScoreByGroup = groupedItems.map(group => ({
    groupId: group.groupId,
    totalScore: group.items.reduce((total, item) => total + (item.sliderValue || 0), 0)
  }));

    return (
      <div style={{ overflowX: 'auto' }}>
        <h5 style={{ fontSize: '1.375rem', fontWeight: '800', color: '#5f6273' }}>INSCRIPCIONES REALIZADAS</h5>

        <TextField
        label="Filtrar"
        value={filterText}
        onChange={this.handleFilterChange}
        style={{ marginBottom: '1rem' }}
      />

      {evaluationsList.isFetching ? (
        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
          <h4 className="sincronizandoTittle">Obteniendo inscripciones a evaluar...</h4>
          <CircularProgress className="circular-progress" />
        </div>
      ) : dataToRender.length > 0 ? (
            <Table responsive={true}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'articleTitle'}
                    direction={orderBy === 'articleTitle' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('articleTitle')}
                  >
                    Título del Trabajo
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'inscriptionDate'}
                    direction={orderBy === 'inscriptionDate' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('inscriptionDate')}
                  >
                    Fecha-Hora
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'competitionId'}
                    direction={orderBy === 'competitionId' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('competitionId')}
                  >
                    Competition Id
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {this.stableSort(dataToRender, this.getComparator(order, orderBy)).map((row) => (
              <TableRow key={row.inscriptionId}>
                <TableCell>{row.articleTitle}</TableCell>
                <TableCell>{row.inscriptionDate}</TableCell>
                <TableCell>{row.competitionId}</TableCell>
                <TableCell>{row.inscriptionId}</TableCell>
                <TableCell>{row.inscriptionId}</TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={() => this.handleOpenView(row.inscriptionId)}>
                    <VisibilityIcon fontSize="large" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="medium !important" onClick={this.handleClick}>
                    <EditIcon fontSize="default" />
                  </IconButton>
                </TableCell> */}
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="medium" onClick={this.handleClick}>
                    <PrintIcon fontSize="small" />
                  </IconButton>
                </TableCell> */}
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <GetAppIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <AssignmentTurnedInIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell> */}
                <TableCell align="center">
                <IconButton aria-label="visualizar" size="small" onClick={() => this.handleOpenScore(row.inscriptionId)}>
                  {/* <IconButton aria-label="visualizar" size="small" onClick={this.handleOpenScore(row.inscriptionId)}> */}
                    <AssessmentIcon fontSize="large" style={{ color: 'var(--backBlue)' }} />
                    
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          </Table>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No se econtraron Inscripciones.
            </Alert>
          )}

{/* DIALOGO DE VISUALIZACIÓN */}
        <Dialog  
          PaperProps={{
            style: {
              paddingBottom: '0 !important',
              // overflowY: 'auto',
              maxHeight: '90vh',
              width: '80vw',          // Ancho al 100% de la ventana del navegador
              maxWidth: 'none !important',  // Sin límite de ancho máximo
              opacity: '1 !important',              // Desactivar la opacidad
              // scrollbarWidth: 'auto',  // Ancho del scrollbar
              // scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
            }
          }} 
          fullWidth={false}
          maxWidth={false}
          className='dialogReounded dialogUbication' 
          open={this.state.openView} 
          aria-labelledby="form-dialog-title"
        >
            <DialogTitle className='dialogTttle' id="alert-dialog-title">RESUMEN TRABAJO A EVALUAR</DialogTitle>
            <DialogContent style={{ paddingLeft: '2rem' }}>
              <DialogContentText id="alert-dialog-description">   
               {this.state.pdfBlobUrl && <iframe src={this.state.pdfBlobUrl} width="100%" height="600px"></iframe>}
              </DialogContentText>              
            </DialogContent>
            <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
              <Button className='btnPrimary' onClick={this.handleCloseView} color="primary">
                Salir
              </Button>
            </DialogActions>
          </Dialog>

{/* DIALOGO DE EVALUACIÓN */}

<Dialog  
  PaperProps={{
    style: {
      paddingBottom: '0 !important',
      overflowY: 'auto',
      maxHeight: '90vh',
      width: '80vw',          // Ancho al 100% de la ventana del navegador
      maxWidth: 'none !important',  // Sin límite de ancho máximo
      opacity: 1,              // Desactivar la opacidad
      scrollbarWidth: 'auto',  // Ancho del scrollbar
      scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
    }
  }} 
  fullWidth={false}
  maxWidth={false}
  className='dialogReounded dialogUbication' 
  open={openScore} 
  aria-labelledby="form-dialog-title"
>
<DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '4rem !important', color: '#5f6273', margin: 0, marginTop: '0rem'}}>EVALUACIÓN DEL TRABAJO ACHO-ID: {selectedInscriptionId}</DialogTitle>
<DialogContent style={{ paddingBottom: '0 !important', overflowY: 'auto', maxHeight: '80vh'  }}>


<div>
        {groupedItems.map((group, groupIndex) => (
          <div key={groupIndex}>
            <h5>{group.groupDescription}</h5>
            {group.items.map((item, itemIndex) => (
              <div key={itemIndex}>
                <h5>{item.formItem}</h5>
                {/* Renderizar un slider o un textarea dependiendo de las condiciones */}
                {item.haveScore === 1 ? (
                  <div>
                   {/* <pre>{JSON.stringify(group, null, 2)}</pre>
                    <pre>{JSON.stringify(item, null, 2)}</pre> */}
                    {/* {item.formOrderGroup}{item.formOrderItem}{item.maxScore} */}
                    <Slider
                      style={{ width: '50%' }}
                      value={item.sliderValue || 0}
                      onChange={(event, newValue) => this.handleSliderChange(group.groupId, item.formOrderItem, newValue)}
                      aria-labelledby="input-slider"
                      marks={Array.from(Array(item.maxScore / 5 + 1).keys()).map(value => ({
                        value: value * 5,
                        label: (value * 5).toString(),
                        style: {
                          height: 12,
                          width: 12,
                          borderRadius: '50%',
                          backgroundColor: '#3f51b5',
                          marginTop: -5 // Ajusta este valor para centrar el círculo verticalmente
                        }
                      }))}
                      min={0}
                      max={item.maxScore} 
                    />
                    <h5>Valor seleccionado: {item.sliderValue || 0}</h5>
                  </div>
                ) : (
                  <div>
                    <textarea 
                      value={item.inputValue || ''} 
                      onChange={(event) => this.handleInputChange(group.groupId, item.formOrderItem, event)}
                      style={{ marginBottom: '1rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
                      rows={4}
                    />
                  </div>
                )}
              </div>
              
            ))}
            <p>Total del grupo: {totalScoreByGroup.find(score => score.groupId === group.groupId)?.totalScore}</p>

          </div>
        ))}
      </div>
</DialogContent>
<DialogActions style={{ justifyContent: 'center', margin: '1rem', marginTop: 0 }}>
 <Button style={{ width: '100px' }} className='btnPrimary' onClick={this.handleCloseScore} color="primary">
   Cancelar
 </Button>
 <Button style={{ width: '100px' }} className='btnPrimary' 
 onClick={this.addScore} color="primary">
   Guardar
 </Button>
</DialogActions>
</Dialog>





      </div>
    );
  }
}

const mapStateToProps = state => ({
  evaluationsList: state.evaluations,
  scoreList      : state.score,
  params: state.getActualParams,
  withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  assignedAssets: state.assignedAssets,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  InventoryLeftOver
);

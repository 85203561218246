import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel,
  Radio,  Grid, withStyles, CircularProgress
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import Badge from "@material-ui/core/Badge";
import { Fab } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { Alert, AlertTitle } from "@material-ui/lab";
import { connect } from "react-redux";
import { fetchInventoryHistory } from "../../redux/actions/inventoryHistoryActions";
import "../../assets/css/online-inventory.css";
import { getCertificate } from "../../redux/actions/assignedAssetActions";
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, getIdOptionSUSIList, getNameOptionSUSIList } from "../../redux/actions/SUSIListsActions";
import { DocumentService } from "../../services/generateDocumentService";
import { pdf } from "@react-pdf/renderer";
import { fetchAssetStages } from "../../redux/actions/assetStagesActions";
import { fetchLocations } from "../../redux/actions/locationsActions";
import { fetchSectionalsUser } from "../../redux/actions/sectionalsActions";
import { fetchAllNotifications } from "../../redux/actions/notificationsActions";
import { Label } from "@material-ui/icons";
import Register from '../Main/Register';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {DOCUMENTS_FOLDER} from "../../config";
import { fetchEvaluators } from "../../redux/actions/evaluatorActions";
import { useEffect } from "react";
import { Tooltip } from '@material-ui/core';



const companyList = [
  { id: 1, name: 'Company A' },
  { id: 2, name: 'Company B' },
  // Agrega más compañías según sea necesario
];

const generatePassword = () => {
  return Math.random().toString(36).slice(-8); // Genera una contraseña aleatoria de 8 caracteres
};



class Evaluator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        idcard: 0,
        name: '',
        lastname: '',
        evaluationType: 0,
        companyId: 0,
        companyName: '',
        username: '',
        password: generatePassword(),
        passwordConfirm: '',
      },
      errors: {},
      messageRegister:  "",
      openCreateEvaluator:  false,
      isValid:              false,
      openDialogSort:       false,
      openDialogFilter:     false,
      valueSort:         'ASC',
      valueFilter:       '',
      optionSelected:        null,
      filterApplied:         false,
      orderBy:           'name',
      order:             'asc',
      filterText: '',
      filteredData: [],
      paramsFetchSectionals: {
        user_susi_id: props.user.user_susi_id,
      },
      clean: false,
      openView: false,
      pdfBlobUrl: ''
    };

  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  validate = () => {
    const { formData } = this.state;
    let formErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key] && key !== 'companyName' && key !== 'passwordConfirm') {
        formErrors[key] = 'Este campo es requerido';
      }
    });
    if (formData.username && !/\S+@\S+\.\S+/.test(formData.username)) {
      formErrors.username = 'Debe ser un correo electrónico válido';
    }
    if (formData.password !== formData.passwordConfirm) {
      formErrors.passwordConfirm = 'Las contraseñas no coinciden';
    }
    this.setState({ errors: formErrors });
    return Object.keys(formErrors).length === 0;
  };




  componentDidMount() {
    this.getInformation();
    //this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
    // this.props.dispatch(fetchAssetsStatus());
    // this.props.dispatch(fetchChangesTypes());
    // this.props.dispatch(fetchAssetStages());
    // this.props.dispatch(fetchMissingReasons());
    // this.props.dispatch(fetchLocations());
    // this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
    this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
  }

  getInformation = () => {
    const params = {
      order: this.state.valueSort,
      vigencia: this.state.valueFilter
    };
    this.props.dispatch(fetchEvaluators(params));
  }


  componentDidUpdate() {
    if (this.state.clean) {
      this.setState({ clean: false });
      this.appliedFilter()
    }
  }

  handleSubmit () {
    if (this.validate()) {
      console.log('Form is valid, submit data...');
      console.log(this.state.formData);
      // Aquí puedes agregar la lógica para enviar los datos del formulario
    }
  };

  handleSortRequest = (property) => {
    const orderBy = property;
    let order = 'desc';
  
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
  
    this.setState({ order, orderBy });
  };
  
  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };


  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };
  
  handleFilterChange = (event) => {
    const filterText = event.target.value.toLowerCase(); // Convertir a minúsculas
  
    const { evaluators } = this.props.evaluatorsList;
  
    if (!Array.isArray(evaluators)) {
      return;
    }
  
    this.setState({
      filterText: filterText
    }, () => {
      const filteredData = evaluators.filter((item) => {
        return (
          item.name.toLowerCase().includes(this.state.filterText) ||
          item.lastname.toLowerCase().includes(this.state.filterText) ||
          item.evaluationType.toString().includes(this.state.filterText) // Convertir a cadena de texto
        );
      });
  
      this.setState({
        filteredData
      });
    });
  };

  downloadFile = async (inscriptionId) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const pdfPath = process.env.PUBLIC_URL + DOCUMENTS_FOLDER + currentYear + '/ArticlePdf_' + inscriptionId + '.pdf';
    console.log("path en historico");
    console.log(process.env.PUBLIC_URL);
    console.log(pdfPath);
    // Descargar el archivo PDF como un Blob
    const response = await fetch(pdfPath);
    const blob = await response.blob();

    // Crear una URL para el Blob
    const blobUrl = URL.createObjectURL(blob);

    // Actualizar el estado con la URL del Blob
    this.setState({ pdfBlobUrl: blobUrl });
  }


  handleOpenView = (inscriptionId) => {
    this.downloadFile(inscriptionId);
    this.setState({
      openView: true
    });
  };

  handleCloseView = () => {
    this.setState({
      openView: false
    });
  };

  handleOpenCreateEvaluator = () => {
    this.setState({
      openCreateEvaluator: true
    });
  };

  handleCloseCreateEvaluator = () => {
    this.setState({
      openCreateEvaluator: false
    });
  };


  render() {
    
    const { classes } = this.props;
    const { formData, errors } = this.state;
    const { evaluatorsList } = this.props;
    console.log("evaluatorsList:", evaluatorsList);
    const { order, orderBy, filterText, filteredData } = this.state;
    const dataToRender = filterText ? filteredData : evaluatorsList.evaluators;
    console.log(dataToRender);
    return (
      <div style={{ overflowX: 'auto' }}>
        <h5 style={{ fontSize: '1.375rem', fontWeight: '800', color: '#5f6273' }}>EVALUADORES REGISTRADOS</h5>

        <TextField
        label="Filtrar"
        value={filterText}
        onChange={this.handleFilterChange}
        style={{ marginBottom: '1rem' }}
      />

      {dataToRender.length > 0 ? (
            <Table responsive={true}>
            <TableHead>
            <TableRow>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start' alignItems='center'>
                      <Fab color="success" size="small" aria-label="add" onClick={() => this.handleOpenCreateEvaluator()}> 
                        <AddIcon color="rgb(0, 166, 206)" />
                      </Fab>
                      <label className='labelBold' >Evaluadores:</label>

                  </Grid>
                </Grid>             
            </TableRow>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'evaluationType'}
                    direction={orderBy === 'evaluationType' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('evaluationType')}
                  >
                    Tipo de evaluador
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('name')}
                  >
                    Nombres
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'lastname'}
                    direction={orderBy === 'lastname' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('lastname')}
                  >
                    Apellidos
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {this.stableSort(dataToRender, this.getComparator(order, orderBy)).map((row) => (
              <TableRow key={row.idUser}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.lastName}</TableCell>
                <TableCell>{row.evaluationType}</TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={() => this.handleOpenView(row.inscriptionId)}>
                    <VisibilityIcon fontSize="large" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell> */}
                <TableCell align="center">
                  <IconButton aria-label="editar" size="small" onClick={this.handleClick}>
                    <EditIcon fontSize="large" />
                  </IconButton>
                </TableCell>
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <GetAppIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <AssignmentTurnedInIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <AssessmentIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <StarHalfIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Button variant="contained" color="secondary" onClick={() => this.handlePrint(row.inscriptionId)}>
                    Imprimir
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
          </Table>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No se econtraron Evaluadores.
            </Alert>
          )}

{/* DIALOGO CREACION DE EVALUADOR*/}
<Dialog
      PaperProps={{
        style: {
          paddingBottom: '0 !important',
          maxHeight: '90vh',
          width: '80vw',
          maxWidth: 'none !important',
          opacity: '1 !important',
        }
      }}
      fullWidth={false}
      maxWidth={false}
      className='dialogReounded dialogUbication'
      open={this.state.openCreateEvaluator}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className='dialogTttle' id="alert-dialog-title">CREACIÓN DE EVALUADOR</DialogTitle>
      <DialogContent style={{ paddingLeft: '2rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Box>
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Cédula
            </Typography>
            <TextField
              fullWidth
              name="idcard"
              value={formData.idcard}
              onChange={this.handleChange}
              error={!!errors.idcard}
              helperText={errors.idcard}
            />
          </Box>
          <Box>
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Nombres
            </Typography>
            <TextField
              fullWidth
              name="name"
              value={formData.name}
              onChange={this.handleChange}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Box>
          <Box>
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Apellidos
            </Typography>
            <TextField
              fullWidth
              name="lastname"
              value={formData.lastname}
              onChange={this.handleChange}
              error={!!errors.lastname}
              helperText={errors.lastname}
            />
          </Box>
          <Box>
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Tipo de evaluador
            </Typography>
            <FormControl fullWidth error={!!errors.companyId}>
              <Select
                name="companyId"
                value={formData.evaluationType}
                onChange={(e) => {
                  const selectedEvaluationType = companyList.find(
                    (company) => company.id === Number(e.target.value)
                  );
                  this.setState((prevState) => ({
                    formData: {
                      ...prevState.formData,
                      companyId: e.target.value,
                      companyName: selectedEvaluationType ? selectedEvaluationType.name : '',
                    },
                  }));
                }}
              >
                <MenuItem value="0">Selecciona el tipo de evaluación que realiza</MenuItem>
                {companyList.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.companyId && (
                <Typography className={classes.errorMessage}>{errors.companyId}</Typography>
              )}
            </FormControl>
          </Box>
          <Box>
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Institución 
            </Typography>
            <FormControl fullWidth error={!!errors.companyId}>
              <Select
                name="companyId"
                value={formData.companyId}
                onChange={(e) => {
                  const selectedCompany = companyList.find(
                    (company) => company.id === Number(e.target.value)
                  );
                  this.setState((prevState) => ({
                    formData: {
                      ...prevState.formData,
                      companyId: e.target.value,
                      companyName: selectedCompany ? selectedCompany.name : '',
                    },
                  }));
                }}
              >
                <MenuItem value="0">Selecciona la institución a la que pertenece</MenuItem>
                {companyList.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.companyId && (
                <Typography className={classes.errorMessage}>{errors.companyId}</Typography>
              )}
            </FormControl>
          </Box>
          <Box>
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Usuario (Correo electrónico usuario@correo.com)
            </Typography>
            <TextField
              fullWidth
              name="username"
              value={formData.username}
              onChange={this.handleChange}
              error={!!errors.username}
              helperText={errors.username}
            />
          </Box>
          {/* <Box >
            <Typography variant="h6" component="label" sx={{ display: 'block', marginBottom: '4px' }}>
              Contraseña
            </Typography>
            <TextField
              fullWidth
              name="password"
              type="password"
              value={formData.password}
              InputProps={{
                readOnly: true,
              }}
              error={!!errors.password}
              helperText={errors.password}
            />
          </Box> */}
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
      <Button
          className='btnPrimary'
          onClick={this.handleCloseCreateEvaluator}
          color="primary"
          sx={{
            width: { xs: '100%', sm: 'auto' },
            mb: { xs: 1, sm: 0 }, // Añadir un margen en la parte inferior en pantallas pequeñas
          }}
        >
          Salir
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleSubmit}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>

{/* DIALOGO DE VISUALIZACIÓN */}
<Dialog  
          PaperProps={{
            style: {
              paddingBottom: '0 !important',
              // overflowY: 'auto',
              maxHeight: '90vh',
              width: '80vw',          // Ancho al 100% de la ventana del navegador
              maxWidth: 'none !important',  // Sin límite de ancho máximo
              opacity: '1 !important',              // Desactivar la opacidad
              // scrollbarWidth: 'auto',  // Ancho del scrollbar
              // scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
            }
          }} 
          fullWidth={false}
          maxWidth={false}
          className='dialogReounded dialogUbication' 
          open={this.state.openView} 
          aria-labelledby="form-dialog-title"
        >

            <DialogTitle className='dialogTttle' id="alert-dialog-title">TRABAJO INSCRITO</DialogTitle>
            <DialogContent style={{ paddingLeft: '2rem' }}>
              <DialogContentText id="alert-dialog-description">
               {this.state.pdfBlobUrl && <iframe src={this.state.pdfBlobUrl} width="100%" height="600px"></iframe>}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
              <Button className='btnPrimary' onClick={this.handleCloseView} color="primary">
                Salir
              </Button>
            </DialogActions>
          </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  evaluatorsList: state.evaluators,
  params: state.getActualParams,
  withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  assignedAssets: state.assignedAssets,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(Evaluator);
import {REACT_APP_BACKEND_URL} from "../../config";

import axios from 'axios';

export const createScore = async (formData) => {
    console.log("crear Calificacion");
    console.log(formData);
    try {
        const response = await axios.post(
            `${REACT_APP_BACKEND_URL}createScore.php`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        console.log("responde");
        console.log(response.data);
        if (!response.data.ok) {
            console.error('Ha surgido el siguiente error al crear la inscripción: ');
        }
        return response.data;
    } catch (error) {
        console.error('Error al crear la inscripción: ', error);
        return error;
    }
}


export const getScore = async (params) => {
    let paramsStrings = "";
    for (const param in params) {
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }
    return axios.get(REACT_APP_BACKEND_URL + '/score?' + paramsStrings).then((resp) => {
        return resp.data
    }).catch(e => {
        console.error('Error al obtener inscripción: ',e);
        return null
    })
}

import {FETCH_CATEGORY_REQUEST, FETCH_CATEGORY_SUCCESS, FETCH_CATEGORY_ERROR,
    FETCH_PRESENTATION_REQUEST, FETCH_PRESENTATION_SUCCESS, FETCH_PRESENTATION_ERROR,
    FETCH_CITY_REQUEST, FETCH_CITY_SUCCESS, FETCH_CITY_ERROR,
    FETCH_INVENTORY_OFFICERS_REQUEST, FETCH_INVENTORY_OFFICERS_SUCCESS, FETCH_INVENTORY_OFFICERS_ERROR,
    FETCH_ASSETS_STATUS_REQUEST, FETCH_ASSETS_STATUS_SUCCESS, FETCH_ASSETS_STATUS_ERROR,
    FETCH_CHANGES_TYPES_REQUEST, FETCH_CHANGES_TYPES_SUCCESS, FETCH_CHANGES_TYPES_ERROR,
    FETCH_MISSING_REASONS_REQUEST, FETCH_MISSING_REASONS_SUCCESS, FETCH_MISSING_REASONS_ERROR, FETCH_ASSETS_SURPLUS_TYPELIST_REQUEST, FETCH_ASSETS_SURPLUS_TYPELIST_SUCCESS, FETCH_ASSETS_SURPLUS_TYPELIST_ERROR, FETCH_TYPE_TRANSACT_TYPELIST_REQUEST, FETCH_TYPE_TRANSACT_TYPELIST_SUCCESS, FETCH_TYPE_TRANSACT_TYPELIST_ERROR, FETCH_TYPE_DOCUMENT_TYPELIST_REQUEST, FETCH_TYPE_DOCUMENT_TYPELIST_SUCCESS, FETCH_TYPE_DOCUMENT_TYPELIST_ERROR, FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_REQUEST, FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_SUCCESS, FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_ERROR, FETCH_TRANSACT_TYPES_DOCUMENT_REQUEST, FETCH_TRANSACT_TYPES_DOCUMENT_SUCCESS, FETCH_TRANSACT_TYPES_DOCUMENT_ERROR, FETCH_REPRINT_REASON_REQUEST, FETCH_REPRINT_REASON_SUCCESS, FETCH_REPRINT_REASON_ERROR
} from "../types";

const initialState = {
    categories: [],
    surplusAssetTypes: [],
    inventoryOfficers: [],
    assetsStatus: [],
    changesTypes: [],
    missingReasons: [],
    typeTransact: [],
    typeDcoument: [],
    replacementStatus: [],
    transactTypesDocuments: [],
    reprintReasons: [],
    isFetching: false,
    error: null
}

export default function SUSILists(state = initialState, action) {
    switch (action.type) {


        case FETCH_CATEGORY_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                categories: action.payload.categories
            };
        case FETCH_CATEGORY_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
 
            case FETCH_PRESENTATION_REQUEST:
                return {
                    ...state,
                    isFetching: true
                };
            case FETCH_PRESENTATION_SUCCESS:
                return {
                    ...state,
                    isFetching: false,
                    presentation: action.payload.presentation
                };
            case FETCH_PRESENTATION_ERROR:
                return {
                    ...state,
                    isFetching: false,
                    error: action.error
                };
    
                case FETCH_CITY_REQUEST:
                    return {
                        ...state,
                        isFetching: true
                    };
                case FETCH_CITY_SUCCESS:
                    return {
                        ...state,
                        isFetching: false,
                        city: action.payload.city
                    };
                case FETCH_CITY_ERROR:
                    return {
                        ...state,
                        isFetching: false,
                        error: action.error
                    };

        case FETCH_INVENTORY_OFFICERS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_INVENTORY_OFFICERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                inventoryOfficers: action.payload.officers
            };
        case FETCH_INVENTORY_OFFICERS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        case FETCH_ASSETS_STATUS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_ASSETS_STATUS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                assetsStatus: action.payload.status
            };
        case FETCH_ASSETS_STATUS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_CHANGES_TYPES_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_CHANGES_TYPES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                changesTypes: action.payload.changes
            };
        case FETCH_CHANGES_TYPES_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_MISSING_REASONS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_MISSING_REASONS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                missingReasons: action.payload.missing_reasons
            };
        case FETCH_MISSING_REASONS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_ASSETS_SURPLUS_TYPELIST_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_ASSETS_SURPLUS_TYPELIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                surplusAssetTypes: action.payload.types
            };
        case FETCH_ASSETS_SURPLUS_TYPELIST_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_TYPE_TRANSACT_TYPELIST_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_TYPE_TRANSACT_TYPELIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                typeTransact: action.payload.types
            };
        case FETCH_TYPE_TRANSACT_TYPELIST_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_TYPE_DOCUMENT_TYPELIST_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_TYPE_DOCUMENT_TYPELIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                typeDcoument: action.payload.types
            };
        case FETCH_TYPE_DOCUMENT_TYPELIST_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                replacementStatus: action.payload.types
            };
        case FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_TRANSACT_TYPES_DOCUMENT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_TRANSACT_TYPES_DOCUMENT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                transactTypesDocuments: action.payload.types
            };
        case FETCH_TRANSACT_TYPES_DOCUMENT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        case FETCH_REPRINT_REASON_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_REPRINT_REASON_SUCCESS:
            return {
                ...state,
                isFetching: false,
                reprintReasons: action.payload.reasons
            };
        case FETCH_REPRINT_REASON_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}
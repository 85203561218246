import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, RadioGroup, FormControlLabel,
  Radio, Button, TextField, Grid, withStyles, CircularProgress
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { connect } from "react-redux";
import { fetchInventoryHistory } from "../../redux/actions/inventoryHistoryActions";
import "../../assets/css/online-inventory.css";
import { getCertificate } from "../../redux/actions/assignedAssetActions";
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, getIdOptionSUSIList, getNameOptionSUSIList } from "../../redux/actions/SUSIListsActions";
import { DocumentService } from "../../services/generateDocumentService";
import { pdf } from "@react-pdf/renderer";
import { fetchAssetStages } from "../../redux/actions/assetStagesActions";
import { fetchLocations } from "../../redux/actions/locationsActions";
import { fetchSectionalsUser } from "../../redux/actions/sectionalsActions";
import { fetchAllNotifications } from "../../redux/actions/notificationsActions";
import { Label } from "@material-ui/icons";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {DOCUMENTS_FOLDER} from "../../config";

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5',
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class InventoryHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textSearch: "",
      openDialogSort: false,
      openDialogFilter: false,
      valueSort: 'ASC',
      valueFilter: '',
      optionSelected: null,
      filterApplied: false,
      orderBy: 'articleTitle',
      order: 'asc',
      filterText: '',
      filteredData: [],
      paramsFetchSectionals: {
        user_susi_id: props.user.user_susi_id,
      },
      clean: false,
      openView: false,
      pdfBlobUrl: ''
    };
  }

  componentDidMount() {
    console.log("TLA en toreport (inventoryhistory 56");
    this.getInformation();
    //this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
    this.props.dispatch(fetchAssetsStatus());
    this.props.dispatch(fetchChangesTypes());
    this.props.dispatch(fetchAssetStages());
    this.props.dispatch(fetchMissingReasons());
    this.props.dispatch(fetchLocations());
    this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
    this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
  }

  getInformation = () => {
    const params = {
      idUser: this.props.user.numero_documento,
      order: this.state.valueSort,
      vigencia: this.state.valueFilter
    };
    this.props.dispatch(fetchInventoryHistory(params));
  }


  componentDidUpdate() {
    if (this.state.clean) {
      this.setState({ clean: false });
      this.appliedFilter()
    }
  }


  handleSortRequest = (property) => {
    const orderBy = property;
    let order = 'desc';
  
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
  
    this.setState({ order, orderBy });
  };
  
  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };


  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };
  
  handleFilterChange = (event) => {
    const filterText = event.target.value.toLowerCase(); // Convertir a minúsculas
  
    const { history } = this.props.historyList;
  
    if (!Array.isArray(history)) {
      return;
    }
  
    this.setState({
      filterText: filterText
    }, () => {
      const filteredData = history.filter((item) => {
        return (
          item.articleTitle.toLowerCase().includes(this.state.filterText) ||
          item.inscriptionDate.toLowerCase().includes(this.state.filterText) ||
          item.competitionId.toString().includes(this.state.filterText) // Convertir a cadena de texto
        );
      });
  
      this.setState({
        filteredData
      });
    });
  };

  downloadFile = async (inscriptionId) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const pdfPath = process.env.PUBLIC_URL + DOCUMENTS_FOLDER + currentYear + '/ArticlePdf_' + inscriptionId + '.pdf';
    console.log("path en historico");
    console.log(process.env.PUBLIC_URL);
    console.log(pdfPath);
    // Descargar el archivo PDF como un Blob
    const response = await fetch(pdfPath);
    const blob = await response.blob();

    // Crear una URL para el Blob
    const blobUrl = URL.createObjectURL(blob);

    // Actualizar el estado con la URL del Blob
    this.setState({ pdfBlobUrl: blobUrl });
  }


  handleOpenView = (inscriptionId) => {
    this.downloadFile(inscriptionId);
    this.setState({
      openView: true
    });
  };

  handleCloseView = () => {
    this.setState({
      openView: false
    });
  };



  render() {
    const { historyList } = this.props;
    const { order, orderBy, filterText, filteredData } = this.state;
    const dataToRender = filterText ? filteredData : historyList.history;
    return (
      <div style={{ overflowX: 'auto' }}>
        <h5 style={{ fontSize: '1.375rem', fontWeight: '800', color: '#5f6273' }}>INSCRIPCIONES REALIZADAS</h5>

        <TextField
        label="Filtrar"
        value={filterText}
        onChange={this.handleFilterChange}
        style={{ marginBottom: '1rem' }}
      />

      {dataToRender.length > 0 ? (
            <Table responsive={true}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'articleTitle'}
                    direction={orderBy === 'articleTitle' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('articleTitle')}
                  >
                    Título del Trabajo
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'inscriptionDate'}
                    direction={orderBy === 'inscriptionDate' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('inscriptionDate')}
                  >
                    Fecha-Hora
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'competitionId'}
                    direction={orderBy === 'competitionId' ? order : 'asc'}
                    onClick={() => this.handleSortRequest('competitionId')}
                  >
                    Competition Id
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {this.stableSort(dataToRender, this.getComparator(order, orderBy)).map((row) => (
              <TableRow key={row.inscriptionId}>
                <TableCell>{row.articleTitle}</TableCell>
                <TableCell>{row.inscriptionDate}</TableCell>
                <TableCell>{row.competitionId}</TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={() => this.handleOpenView(row.inscriptionId)}>
                    <VisibilityIcon fontSize="large" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell> */}
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <StarHalfIcon fontSize="large" />
                  </IconButton>
                </TableCell>
                {/* <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <GetAppIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <AssignmentTurnedInIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <AssessmentIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton aria-label="visualizar" size="small" onClick={this.handleClick}>
                    <StarHalfIcon fontSize="small" style={{ color: 'var(--backBlue)' }} />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Button variant="contained" color="secondary" onClick={() => this.handlePrint(row.inscriptionId)}>
                    Imprimir
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
          </Table>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No se econtraron Inscripciones.
            </Alert>
          )}



{/* DIALOGO DE VISUALIZACIÓN */}
<Dialog  
          PaperProps={{
            style: {
              paddingBottom: '0 !important',
              // overflowY: 'auto',
              maxHeight: '90vh',
              width: '80vw',          // Ancho al 100% de la ventana del navegador
              maxWidth: 'none !important',  // Sin límite de ancho máximo
              opacity: '1 !important',              // Desactivar la opacidad
              // scrollbarWidth: 'auto',  // Ancho del scrollbar
              // scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
            }
          }} 
          fullWidth={false}
          maxWidth={false}
          className='dialogReounded dialogUbication' 
          open={this.state.openView} 
          aria-labelledby="form-dialog-title"
        >
            <DialogTitle className='dialogTttle' id="alert-dialog-title">TRABAJO INSCRITO</DialogTitle>
            <DialogContent style={{ paddingLeft: '2rem' }}>
              <DialogContentText id="alert-dialog-description">
               {this.state.pdfBlobUrl && <iframe src={this.state.pdfBlobUrl} width="100%" height="600px"></iframe>}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
              <Button className='btnPrimary' onClick={this.handleCloseView} color="primary">
                Salir
              </Button>
            </DialogActions>
          </Dialog>





      </div>
    );
  }
}

const mapStateToProps = state => ({
  historyList: state.inventoryHistory,
  params: state.getActualParams,
  withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  assignedAssets: state.assignedAssets,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  InventoryHistory
);

import {
  FETCH_EVALUATIONS_ERROR,
  FETCH_EVALUATIONS_SUCCESS,
  FETCH_EVALUATIONS_REQUEST,
} from "../types/index";
import {REACT_APP_BACKEND_URL} from "../../config";

import axios from "axios";

export const fetchEvaluations = (params) => (dispatch) => {
  let paramsStrings = "";
  for (const param in params) {
    if (paramsStrings.length > 0) {
      paramsStrings += "&";
    }
    paramsStrings += param + "=" + params[param];
  }

  dispatch({ type: FETCH_EVALUATIONS_REQUEST });
  axios.get(REACT_APP_BACKEND_URL + "/getEvaluations.php?" + paramsStrings).then((resp) => {
      console.log("evaluations action");
      console.log(resp);
      dispatch({
        type: FETCH_EVALUATIONS_SUCCESS,
        payload: {
          evaluations: resp.data
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: FETCH_EVALUATIONS_ERROR,
        error: e.toString(),
      });
    });
};
